import React from "react";

// @material-ui icons

import ForumIcon from '@material-ui/icons/Forum';
import CachedIcon from '@material-ui/icons/Cached';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js";
import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";
import { Grid, Box } from "@material-ui/core";

import iphone from "assets/img/sections/circle3.png";

import Extension from "@material-ui/icons/Extension";



import consultingPic from "assets/img/examples/card-profile5.jpg";
import technologyPic from "assets/img/update1/web5.jpg";

// @material-ui/icons
import Build from "@material-ui/icons/Build";
import Subject from "@material-ui/icons/Subject";
import FormatPaint from "@material-ui/icons/FormatPaint";
import Code from "@material-ui/icons/Code";
import Dashboard from "@material-ui/icons/Dashboard";
import Timeline from "@material-ui/icons/Timeline";
import Group from "@material-ui/icons/Group";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Badge from "components/Badge/Badge.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";
import pricingStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/pricingStyle.js";

import office2 from "assets/img/examples/office2.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";
import cardProject1 from "assets/img/examples/card-project1.jpg";
import cardProject2 from "assets/img/examples/card-project2.jpg";
import cardProject3 from "assets/img/examples/card-project3.jpg";
import cardProject4 from "assets/img/examples/card-project4.jpg";
import cardProject5 from "assets/img/examples/card-project5.jpg";
import cardProject6 from "assets/img/examples/card-project6.jpg";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import iphone2 from "assets/img/sections/iphone2.png";

import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import DevicesIcon from '@material-ui/icons/Devices';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import RateReviewIcon from '@material-ui/icons/RateReview';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';


import services2 from "assets/img/test/services3.png";
import api3 from "assets/img/test/api4.png";
import ui1 from "assets/img/test/ui1.png";

import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";


const useStyles = makeStyles(projectsStyle);
const useFeaturesStyles = makeStyles(featuresStyle);
const usePricingStyles = makeStyles(pricingStyle);

export default function SectionDescription({ ...rest }) {
  const classes = useStyles();
  const featuresClasses = useFeaturesStyles();
  const pricingClasses = usePricingStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.projects + " " + classes.projects4}>
        <div className={classes.container}>
          {/* Feature 4 START */}
          <div className={featuresClasses.features4}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={
                  classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                }
              >
                <h1 className={classes.title}>
                  Our Products
                </h1>
                <Box m={15} />
                <h2 className={classes.title}>
                  PlanLogix Engine
                </h2>
                <h5 className={classes.description}>
                  Software as a Service (SaaS) solution that will enchance your offering with automated financial guidance and product recommendations
                </h5>
                <div className={classes.sectionSpace} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
                <Card
                  plain
                  className={classes.card2}
                >
                  <CardHeader plain>
                    <img src={api3} style={{ maxHeight: 400 }} alt="..." />
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
                <InfoArea
                  className={classes.info4}
                  title="Automate Financial Guidance"
                  description="Suggest relevant financial priorities and point out potential issues and opportunities, based on your client's individual situation."
                  icon={FormatListNumberedIcon}
                  iconColor="primary"
                />
                <br />
                <InfoArea
                  className={classes.info4}
                  title="Provide Product Recommentations"
                  description="Offer your products with a high degree of confidence and relevance, matching closely to your client's goals and financial needs."
                  icon={AccountBalanceIcon}
                  iconColor="danger"
                />
                <br />
                <InfoArea
                  className={classes.info4}
                  title="Utilize Financial Calculations"
                  description="Add powerful calculation capabilities to your offering, either for specific use-cases or for running complex scenarios."
                  icon={AssessmentIcon}
                  iconColor="info"
                />
              </GridItem>
            </GridContainer>
            <hr />
            <GridContainer id="uis">
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={
                  featuresClasses.mlAuto + " " + featuresClasses.mrAuto + " " + featuresClasses.textCenter
                }
              >
                <h2 className={featuresClasses.title}>Financial Assessments</h2>
                <h5 className={featuresClasses.description}>
                  Personal financial assessments for web and mobile apps, that can be used by your clients or serve as a part of your advisor-driven workflows.
                </h5>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={3} className={featuresClasses.mlAuto}>
                <InfoArea
                  icon={DashboardIcon}
                  title="Modular Assessments"
                  description="The modular nature of our financial planning assessments allows selected topics to be utilized directly in your website or mobile app."
                  iconColor="danger"
                />
                <InfoArea
                  icon={ViewComfyIcon}
                  title="Holistic Review"
                  description="In addition to the modular approach, you can gather a complete financial picture of your clients, either in self-served or advisor-driven fashions."
                  iconColor="success"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={4}>
                <div className={featuresClasses.phoneContainer}>
                  <img src={ui1} alt="..." />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={3} className={featuresClasses.mrAuto}>
                <InfoArea
                  icon={PermIdentityIcon}
                  title="Client Self-Serve"
                  description="Provide easy-to-use financial assessments to be used directly by your clients, with the ability to re-use their data between different modules."
                  iconColor="info"
                />
                <InfoArea
                  icon={SupervisorAccountIcon}
                  title="Advisor-Driven"
                  description="Provide a dedicated dashboard to your advisory and support teams, for access to advanced analysis tools that show the value of professional advice."
                  iconColor="primary"
                />
              </GridItem>
            </GridContainer>
            <hr />
            {/* Feature 1 START */}
            <div className={featuresClasses.features1}>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={8}
                  md={8}
                  className={featuresClasses.mlAuto + " " + featuresClasses.mrAuto}
                >
                  <h2 className={featuresClasses.title}>Sample Use-Cases</h2>
                  <h5 className={featuresClasses.description}>
                  </h5>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4} md={4}>
                  <InfoArea
                    vertical
                    icon={DevicesIcon}
                    title="Suggest New Products"
                    description="You would like to automatically suggest products that fit your new or existing clients, from any of your communication channels such as website, email or mobile app. PlanLogix Engine can help with creating relevant suggestions based on your client's profile."
                    iconColor="success"
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <InfoArea
                    vertical
                    icon={MobileScreenShareIcon}
                    title="Tailored Financial Wellness Tips"
                    description="You would like to make personalized financial wellness suggestions to your clients when running a new email campaign or via your client portal/mobile app. PlanLogix Engine can point out suggested priorities and fincial tips based on each individual situation."
                    iconColor="info"
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <InfoArea
                    vertical
                    icon={LocalLibraryIcon}
                    title="Offer Financial Assessments"
                    description="You would like to provide your clients with personal financial assessments that can be used by them directly from your public/internal website or your mobile app. Your clients can make their own use of the product or in collaboration with your advisory team."
                    iconColor="danger"
                  />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        {/* Feature 1 END */}
      </div>
    </div>
  );
}
