import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";

import bg5 from "assets/img/bg5.jpg";
import SavingsImage from "assets/img/blog/entrepreneur-working-with-bills_1098-20001.jpg";
import TechnologyImage from "assets/img/blog/male-hand-holding-phone_155003-6466.jpg";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";

const useStyles = makeStyles(sectionInterestedStyle);

export default function SectionInterested() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
              <a href="#pablo">
                <img src={bg5} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + bg5 + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>RETIREMENT</h6>
              </Info>
              <h4 className={classes.cardTitle}>
              <a href="https://www.canada.ca/en/revenue-agency/campaigns/cpp-need-know.html" target="_blank">
                  CPP Rates are increasing in 2021
                </a>
              </h4>
              <p className={classes.description}>
                On January 1, 2021, the CPP contribution rate will increase from 5.25% to 5.45% on CPP pensionable earnings. (www.canada.ca)
                <a href="https://www.canada.ca/en/revenue-agency/campaigns/cpp-need-know.html" target="_blank"> Read More </a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <a href="#pablo">
                <img src={SavingsImage} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + SavingsImage + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Success>
                <h6>SAVINGS</h6>
              </Success>
              <h4 className={classes.cardTitle}>
              <a href="https://www.investmentexecutive.com/news/industry-news/2021-tfsa-limit-announced/" target="_blank">
                  2021 TFSA limit announced
                </a>
              </h4>
              <p className={classes.description}>
                The TFSA new contribution limit for 2021 has been officially released. That limit is $6,000, matching the amount set in 2019 and 2020. (www.investmentexecutive.com)
                <a href="https://www.investmentexecutive.com/news/industry-news/2021-tfsa-limit-announced/" target="_blank"> Read More </a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <a href="#pablo">
                <img src={TechnologyImage} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + TechnologyImage + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Danger>
                <h6>TECHNOLOGY</h6>
              </Danger>
              <h4 className={classes.cardTitle}>
              <a href="https://www.investmentexecutive.com/news/research-and-markets/consumer-banks-face-disruption-from-tech-giants/" target="_blank">
                  Google enters banking business
                </a>
              </h4>
              <p className={classes.description}>
                  Google’s parent company, Alphabet Inc., is launching digital bank accounts within the Google Pay app in the coming year, in competition for deposits.(www.investmentexecutive.com)
                <a href="https://www.investmentexecutive.com/news/research-and-markets/consumer-banks-face-disruption-from-tech-giants/" target="_blank"> Read More </a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
