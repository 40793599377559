import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Icon from "@material-ui/core/Icon";

// @material-ui icons

import ForumIcon from '@material-ui/icons/Forum';
import CachedIcon from '@material-ui/icons/Cached';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js";
import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";
import { Grid, Box } from "@material-ui/core";

import iphone from "assets/img/sections/circle3.png";

import Extension from "@material-ui/icons/Extension";


// @material-ui/icons
import Build from "@material-ui/icons/Build";
import Subject from "@material-ui/icons/Subject";

// core components
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import consultingPic from "assets/img/examples/card-profile5.jpg";
import technologyPic from "assets/img/update1/web5.jpg";
import TechnologyDescription from "./TechnologyDescription";

const useStyles = makeStyles(descriptionStyle);
const useFeaturesStyles = makeStyles(featuresStyle);

export default function SectionDescription({ ...rest }) {
  const classes = useStyles();
  const featuresClasses = useFeaturesStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Project 1 START */}
      <div className={classes.section}>
        <div className={classes.container}>
          <Grid container justify="center" alignItems="center" direction="column">
            <GridItem xs={12}>
              <h2 className={classes.title} style={{ textAlign: 'center' }}>Our Offering</h2>
            </GridItem>
            <GridItem xs={12}>
              <h4 className={classes.description}>
                PlanLogix assists other companies in providing financial planning technology to their clients and their teams
              </h4>
            </GridItem>
          </Grid>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <Card
                raised
                background
                style={{ backgroundImage: `url(${technologyPic})` }}
              >
                <CardBody background>
                  <a href="#pablito" onClick={e => e.preventDefault()}>
                    <h3 className={classes.cardTitleWhite}>
                      <b>Financial Planning Technology</b>
                    </h3>
                  </a>
                  <h4>
                    Accelerate your delivery with PlanLogix APIs to utilize our powerful financial planning calculations and application engines. Build your own UI or use a set of our pre-built UIs to accelerate your time-to-market.
                  </h4>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card
                raised
                background
                className={classes.card4}
                style={{ backgroundImage: `url(${consultingPic})` }}
              >
                <CardBody background>
                  <a href="#pablito" onClick={e => e.preventDefault()}>
                    <h3 className={classes.cardTitleWhite}>
                      <b>Consulting Services</b>
                    </h3>
                  </a>
                  <h4>
                    Our team can help with niche expertise to identify the best financial planning and advice solutions for your company, in line with your business and technology targets
                  </h4>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <TechnologyDescription />
    </div>
  );
}
