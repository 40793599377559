import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import GroupWork from "@material-ui/icons/GroupWork";
import Airplay from "@material-ui/icons/Airplay";
import LocationOn from "@material-ui/icons/LocationOn";
import Extension from "@material-ui/icons/Extension";
import ChildFriendly from "@material-ui/icons/ChildFriendly";
import WatchLater from "@material-ui/icons/WatchLater";
import Code from "@material-ui/icons/Code";
import FormatPaint from "@material-ui/icons/FormatPaint";
import Dashboard from "@material-ui/icons/Dashboard";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import AccessTime from "@material-ui/icons/AccessTime";
import AttachMoney from "@material-ui/icons/AttachMoney";
import InsertChartIcon from '@material-ui/icons/InsertChart';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import DevicesIcon from '@material-ui/icons/Devices';
import ChatIcon from '@material-ui/icons/Chat';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import CreditCardIcon from '@material-ui/icons/CreditCard';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PeopleIcon from '@material-ui/icons/People';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import componentsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/componentsStyle.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

import iphone from "assets/img/sections/iphone.png";
import iphone2 from "assets/img/test_collaboration_12.png";
import bg5 from "assets/img/card-project11.jpg";

const useStyles = makeStyles(featuresStyle);

export default function SectionCollaboration({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div>
        {/* Feature 4 START */}
        <div className={classes.features4}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Collaboration Tools</h2>
              <h5 className={classes.description}>
                Today, more than ever you need to stay connected with your clients and engage them in all stages of their financial planning journey
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={3} className={classes.mlAuto}>
              <InfoArea
                icon={DashboardIcon}
                title="Customer Dashboard"
                description="Provide your clients with the ability to login and see their entire financial picture at once, as well as to enable engaging ways to collaboratively follow their financial planning journey"
                iconColor="primary"
              />
              <InfoArea
                icon={AttachFileIcon}
                title="Account Aggregation"
                description="Skip routine manual data entry by allowing your customers to securely integrate their existing accounts into your financial planning processes"
                iconColor="success"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={4} >
              <div className={classes.phoneContainer}>
                <img src={iphone2} alt="..." />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={3} className={classes.mrAuto}>
              <InfoArea
                icon={ChatIcon}
                title="In-App Messaging"
                description="Engage your clients with the ability to ask a question at any time, leave a message or a note and be notified on a timely basis when there is a response"
                iconColor="info"
              />
              <InfoArea
                icon={AttachFileIcon}
                title="File Upload"
                description="Enable your clients and your team members to conveniently upload and securely store clients’ data required for your financial planning processes"
                iconColor="danger"
              />
            </GridItem>
          </GridContainer>
        </div>
        {/* Feature 4 END */}
      </div>
    </div>
  );
}
