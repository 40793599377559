import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";

import RetirementImage from "assets/img/bg5.jpg";
import InvestmentsImage from "assets/img/examples/blog-investment.jpg";
import CryptocurrencyImage from "assets/img/examples/office2.jpg";
import SavingsImage from "assets/img/blog/entrepreneur-working-with-bills_1098-20001.jpg";
import TechnologyImage from "assets/img/blog/male-hand-holding-phone_155003-6466.jpg";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";

const useStyles = makeStyles(sectionInterestedStyle);

export default function SectionInterested() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
              <a href="#">
                <img src={RetirementImage} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + RetirementImage + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>RETIREMENT</h6>
              </Info>
              <h4 className={classes.cardTitle}>
              <a href="https://www.wealthprofessional.ca/news/industry-news/fewer-canadians-under-71-want-to-touch-their-rrsps/337089" target="_blank">
              Focus on Retirement Planning
                </a>
              </h4>
              <p className={classes.description}>
              Events of 2020 caused many clients to review their retirement plans with their advisors. Many Canadians stated that they don’t want to touch their RRSPs until the age of 71 (www.wealthprofessional.ca).
                <a href="https://www.wealthprofessional.ca/news/industry-news/fewer-canadians-under-71-want-to-touch-their-rrsps/337089" target="_blank"> Read more</a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <a href="#pablo">
                <img src={InvestmentsImage} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + InvestmentsImage + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Success>
                <h6>INVESTMENTS</h6>
              </Success>
              <h4 className={classes.cardTitle}>
              <a href="https://www.advisor.ca/investments/market-insights/finding-a-role-for-bonds-in-an-uncertain-economy/" target="_blank">
              Finding a role for bonds
                </a>
              </h4>
              <p className={classes.description}>
              A fresh look at finding a role for bonds in an uncertain economy. Analysis from Manulife shows that there is an opportunity to invest in bonds, despite low yields. (www.advisor.ca)
                <a href="https://www.advisor.ca/investments/market-insights/finding-a-role-for-bonds-in-an-uncertain-economy/" target="_blank"> Read More </a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <a href="#pablo">
                <img src={TechnologyImage} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + TechnologyImage + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Danger>
                <h6>TECHNOLOGY</h6>
              </Danger>
              <h4 className={classes.cardTitle}>
              <a href="https://www.advisor.ca/investments/market-insights/tech-trends-to-track-in-2021/" target="_blank">
              Tech trends to track in 2021
                </a>
              </h4>
              <p className={classes.description}>
              Financial markets rallied last year following the pandemic shock, with the U.S. tech sector boasting annual returns of about 40%. (www.advisor.ca)
                <a href="https://www.advisor.ca/investments/market-insights/tech-trends-to-track-in-2021/" target="_blank"> Read More </a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
