import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";

import RetirementImage from "assets/img/bg5.jpg";
import CryptocurrencyImage from "assets/img/examples/office2.jpg";
import SavingsImage from "assets/img/blog/entrepreneur-working-with-bills_1098-20001.jpg";
import TechnologyImage from "assets/img/blog/male-hand-holding-phone_155003-6466.jpg";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";

const useStyles = makeStyles(sectionInterestedStyle);

export default function SectionInterested() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
              <a href="#">
                <img src={RetirementImage} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + RetirementImage + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>RETIREMENT</h6>
              </Info>
              <h4 className={classes.cardTitle}>
              <a href="https://www.canada.ca/en/revenue-agency/campaigns/cpp-need-know.html" target="_blank">
                Ontario exempting certain IPPs
                </a>
              </h4>
              <p className={classes.description}>
              The Ontario government has passed legislation that reduces the regulatory burden for certain individual pension plans (www.advisor.ca).
                <a href="https://www.advisor.ca/tax/tax-news/ontario-exempting-certain-ipps-from-pension-legislation/" target="_blank"> Read more</a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <a href="#pablo">
                <img src={CryptocurrencyImage} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + CryptocurrencyImage + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Success>
                <h6>CRYPTOCURRENCY</h6>
              </Success>
              <h4 className={classes.cardTitle}>
              <a href="https://www.investmentexecutive.com/news/from-the-regulators/iiroc-calls-for-crypto-experts/" target="_blank">
                IIROC calls for crypto experts
                </a>
              </h4>
              <p className={classes.description}>
              The Investment Industry Regulatory Organization of Canada (IIROC) is seeking new members for its crypto consultation group. (www.investmentexecutive.com)
                <a href="https://www.investmentexecutive.com/news/from-the-regulators/iiroc-calls-for-crypto-experts/" target="_blank"> Read More </a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <a href="#pablo">
                <img src={TechnologyImage} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + TechnologyImage + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Danger>
                <h6>TECHNOLOGY</h6>
              </Danger>
              <h4 className={classes.cardTitle}>
              <a href="https://www.advisor.ca/news/industry-news/banks-shift-tech-focus-to-artificial-intelligence/" target="_blank">
                Banks focus on artificial intelligence
                </a>
              </h4>
              <p className={classes.description}>
                  An article showing how banks are shifting their focus towards artificial intelligence and potentials impacts and outcomes. (www.advisor.ca)
                <a href="https://www.advisor.ca/news/industry-news/banks-shift-tech-focus-to-artificial-intelligence/" target="_blank"> Read More </a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
