import React from "react";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import { Box, Grid } from "@material-ui/core";
import axios from 'axios';

const useStyles = makeStyles(contactsStyle);

export default function SectionContacts({ ...rest }) {
  const [email, setEmail] = React.useState('');
  const [subject, setSubject] = React.useState('');
  const [message, setMessage] = React.useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  }

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const message_body = "Email: " + email + ", Subject: " + subject + ", Message: " + message;

    axios.post(`/email/contact`, JSON.stringify(message_body).slice(1, -1), {headers: {'Content-Type': 'text/plain'}})
      .then(res => {
        
        alert('thank you, your message has been sent');
      }).catch(error => {
        alert('Our appologies, the message could not be sent');
      })

  }
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Contact us 2 START */}
      <div className={classes.contacts2}>
        <Grid container direction="column" justify="center" alignItems="center">
          <GridItem xs={12} sm={6} md={6}>
            <Card className={classes.card2}>
              <form>
                <CardHeader contact color="success" className={classes.textCenter}>
                  <h4 className={classes.cardTitle}>Contact Us</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText="Your Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: email,
                          onChange: handleEmailChange
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText="Subject"
                        id="subject"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: subject,
                          onChange: handleSubjectChange
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <CustomInput
                    labelText="Your Message"
                    id="message"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                      value: message,
                      onChange: handleMessageChange
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentBetween}>
                  <Button color="success" className={classes.pullRight} onClick={handleSubmit}>
                    Send Message
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </Grid>
      </div>
      {/* Contact us 2 END */}
    </div>
  );
}
