import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Quote from "components/Typography/Quote.js";
// core components

import { Box } from "@material-ui/core";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
// core components
import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import Button from "components/CustomButtons/Button.js";
import TradingImage from "assets/img/examples/blog-trading.jpg";
import cardProject6 from "assets/img/blog/business-analysis-planing-solution-objective-strategy-concept.jpg";
import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.js";

import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

import cardBlog1 from "assets/img/update1/smiling-businessman-welcoming-new-partner-group-meeting-with-handshake.jpg";
import cardBlog2 from "assets/img/update1/discussion.jpg";
import cardBlog3 from "assets/img/update1/low-angle-view-businesspeople-stacking-hand-together-workplace.jpg";
import cardBlog4 from "assets/img/update1/group-multinational-busy-people-working-office.jpg";
import cardBlog5 from "assets/img/update1/team-work-hands-jig-saw-unite-with-power-is-good-team-successful-people-team-work-concept.jpg";
import cardBlog6 from "assets/img/examples/card-blog4.jpg";
import office2 from "assets/img/office2.jpg";
import blog5 from "assets/img/examples/blog5.jpg";
import blog6 from "assets/img/examples/blog6.jpg";
import blog7 from "assets/img/examples/blog7.jpg";
import blog8 from "assets/img/examples/blog8.jpg";
import bg5 from "assets/img/bg5.jpg";

import CardAvatar from "components/Card/CardAvatar.js";
import Muted from "components/Typography/Muted.js";
import Warning from "components/Typography/Warning.js";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js";

import dg2 from "assets/img/dg2.jpg";
import cardProfile1Square from "assets/img/faces/card-profile1-square.jpg";
import cardProfile2Square from "assets/img/faces/card-profile2-square.jpg";
import cardProfile4Square from "assets/img/faces/card-profile4-square.jpg";
import cardProfile6Square from "assets/img/faces/card-profile6-square.jpg";


const useStyles = makeStyles(sectionTextStyle);

export default function SectionText() {
  const classes = useStyles();
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            It's About Building Relationships...
          </h3>
          <p>
            How important is it to your organization to connect with customers on a personal level? If you are after solid relationships with who you serve, then genuine care should be the foundation. Many financial institutions choose financial planning to be a central part of their relationship with customers across all lines of business, for a good reason. Delivered in the right form, not only it will help to understand their real needs and to address their concerns, but most importantly it can help them to stay healthy with their finances in the long run and to build a bond of trust with your organization that will last for many years.
          </p>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <GridContainer justify="center" alignItems="center" style={{ height: "100%" }}>
            <GridItem xs={12}>
              <CardHeader image plain>
                <a href="#pablito" onClick={e => e.preventDefault()}>
                  <img src={cardBlog1} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${cardBlog1})`,
                    opacity: "1"
                  }}
                />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${cardBlog1})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12}>
          <Box m={5} />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <GridContainer justify="center" alignItems="center" style={{ height: "100%" }}>
            <GridItem xs={12}>
              <CardHeader image plain>
                <a href="#pablito" onClick={e => e.preventDefault()}>
                  <img src={cardBlog2} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${cardBlog2})`,
                    opacity: "1"
                  }}
                />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${cardBlog2})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
            </GridItem>
          </GridContainer>

        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            Be Customer-Centric
          </h3>
          <p>
            Over the years, we interviewed many financial advisors, some of them more than once in the course of a few years. The sentiment is very similar - a full financial plan is not for everyone. Multiple sources demonstrated that a modular approach and customization to financial planning is the way to go for almost 50% of their customers.
          </p>
          <p>
            It helps to see why that is a trend, if you stay focused on a customer’s needs and ask advisors how they actually do the financial planning - every customer has their unique needs and personality. Many customers may want only a specific need to be addressed and they can be very reluctant to spend time and to share information for things that they don’t consider a priority or don’t see as a problem.
          </p>
        </GridItem>

        <GridItem xs={12}>
          <Box m={5} />
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            Collaboration and Customization are the Keys
          </h3>
          <p>
            If you are still stuck with financial planning paradigms that were invented decades ago, it’s definitely time to rethink! Many advisors were already talking about modular planning and collaboration even back in 2015-2016, and even more so today after COVID-19 brought many new challenges and concepts on how work will be done going forward. You must change your approach or many of your services will become irrelevant very soon.
          </p>
          <p>
            In order to truly understand the needs of your customers, you need to engage in an ongoing dialog in a private setting, ideally triggered by the customers themselves and you should keep it going all the time. This conversational approach will help you stay up-to-date with what they need and being flexible in your ability to address their concerns and questions as they come. It will show your true value as an organization and will build long-lasting relationships.
          </p>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <GridContainer justify="center" alignItems="center" style={{ height: "100%" }}>
            <GridItem xs={12}>

              <CardHeader image plain>
                <a href="#pablito" onClick={e => e.preventDefault()}>
                  <img src={cardBlog3} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${cardBlog3})`,
                    opacity: "1"
                  }}
                />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${cardBlog3})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
            </GridItem>
          </GridContainer>

        </GridItem>
        <GridItem xs={12}>
          <Box m={5} />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <GridContainer justify="center" alignItems="center" style={{ height: "100%" }}>
            <GridItem xs={12}>

              <CardHeader image plain>
                <a href="#pablito" onClick={e => e.preventDefault()}>
                  <img src={cardBlog5} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${cardBlog5})`,
                    opacity: "1"
                  }}
                />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${cardBlog5})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            Collaboration examples
          </h3>
          <p>
            To be more practical, let’s take a look at some collaboration use-cases that financial service companies should be offering to their customers. While there are many permutations of how collaboration can happen here are the two most common examples. The first example shows a scenario with a new customer visiting your company's website. The second scenario is with an existing customer who worked with an advisor but doesn't have a financial plan yet.
          </p>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4} style={{ textAlign: "center" }}>
              <Button round href="#pablo" color="rose" href="/samples/case1.pdf" target="_blank">
                <FormatAlignLeft className={classes.icons} /> CASE 1 (PDF)
          </Button>
            </GridItem>
            <GridItem xs={12} sm={4} md={4} style={{ textAlign: "center" }}>
              <Button round href="#pablo" color="rose" href="/samples/case2.pdf" target="_blank">
                <FormatAlignLeft className={classes.icons} /> CASE 2 (PDF)
          </Button>
            </GridItem>
          </GridContainer>

        </GridItem>
        <GridItem xs={12}>
          <Box m={5} />
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            How Can PlanLogix help?
          </h3>
          <p>
            As a provider of specialized solutions and services in an established market, we can help financial services companies gradually fill the gaps in their financial planning practices, instead of trying to radically change what they do. If you are looking to build strong relationships with your customers, we can partner as a valuable addition to your existing technology landscape. With our ongoing R&D processes in place, you will ensure that your company is always on the top of the curve in the industry with your approach to financial planning technologies.
          </p>
          <Button round href="#pablo" color="success" href="/samples/planlogix_introduction.pdf" target="_blank">
            <FormatAlignLeft className={classes.icons} /> ABOUT PLANLOGIX (PDF)
          </Button>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <GridContainer justify="center" alignItems="center" style={{ height: "100%" }}>
            <GridItem xs={12}>

              <CardHeader image plain>
                <a href="#pablito" onClick={e => e.preventDefault()}>
                  <img src={cardBlog4} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${cardBlog4})`,
                    opacity: "1"
                  }}
                />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${cardBlog4})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12}>
          <Box m={5} />
        </GridItem>
      </GridContainer>
    </div>
  );
}
