import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Quote from "components/Typography/Quote.js";
// core components

import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
// core components
import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import TradingImage from "assets/img/examples/card-project5.jpg";
import cardProject6 from "assets/img/blog/business-analysis-planing-solution-objective-strategy-concept.jpg";
import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.js";
import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";

const useStyles = makeStyles(sectionTextStyle);
const projectStyles = makeStyles(projectsStyle);

export default function SectionText() {
  const classes = useStyles();
  const projectClasses = projectStyles();
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
          <Button color="primary" simple href="/blog/2024/financial-guidance">
            2024 - Financial Guidance
          </Button>
          <Button color="primary" round>
            2023
          </Button>
          <Button color="primary" simple href="/blog/2022">
            2022
          </Button>
          <Button color="primary" simple href="/blog/2021">
            2021
          </Button>
          <Button color="primary" simple href="/blog/2020">
            2020
          </Button>
          <div className={classes.tabSpace} />
        </GridItem>
        <h5 className={projectClasses.description}>
          Our favourite Fintech articles and podcasts from 2023
        </h5>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + TradingImage + ")" }}
          >
            <CardBody background>
              <a href="https://fi.11fs.com/973" target="_blank">
                <h3 className={classes.cardTitle}>
                  Holistic financial wellbeing: 11sf Podcast
                </h3>
              </a>
              <p className={classes.category}>
                Insightful podcast on what holistic financial wellbeing means and how the Fintech industry is approaching it
              </p>

              <a href="https://fi.11fs.com/973" target="_blank" onClick="event.preventDefault()">
                <Button round color="warning">
                  <FormatAlignLeft className={classes.icons} /> READ MORE
                </Button>
              </a>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
