import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import cardsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/cardsStyle.js";

import cardsTest from "assets/img/sections/team_new.png";

const useStyles = makeStyles(cardsStyle);

export default function SectionTeam({...rest}) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
    <div className={classNames(classes.section, classes.sectionDark)}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={7} sm={7}>
            <div className={classes.imageContainer}>
              <img src={cardsTest} alt="views" />
            </div>
          </GridItem>
          <GridItem md={4} sm={5} className={classes.mlAuto}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>About Us</h3>
              <h5 className={classes.description}>
                Based in Toronto, Canada, PlanLogix develops innovative solutions and provides consulting services to financial services companies in the US and Canada, assisting them in streamlining and scaling up the delivery of financial advice to consumers. <br/><br/> Initially focused on consulting activities, our current offering includes a combination of financial guidance products featuring automation capabilities and end-user interface experiences.
              </h5>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
    </div>
  );
}
