import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";

import RetirementImage from "assets/img/bg5.jpg";
import InvestmentsImage from "assets/img/examples/blog-investment.jpg";
import CryptocurrencyImage from "assets/img/examples/office2.jpg";
import SavingsImage from "assets/img/blog/entrepreneur-working-with-bills_1098-20001.jpg";
import TechnologyImage from "assets/img/blog/male-hand-holding-phone_155003-6466.jpg";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";

const useStyles = makeStyles(sectionInterestedStyle);

export default function SectionInterested() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
              <a href="#">
                <img src={RetirementImage} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + RetirementImage + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>RETIREMENT</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <a href="https://www.investmentexecutive.com/feature/special-report-on-retirement-2021/" target="_blank">
                  Special Report on Retirement
                </a>
              </h4>
              <p className={classes.description}>
                With inflation affecting spending and portfolios, your retired clients will need clear counsel. This year's Special Report on Retirement provides insights... (www.investmentexecutive.com).
                <a href="https://www.investmentexecutive.com/feature/special-report-on-retirement-2021/" target="_blank"> Read more</a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <a href="#pablo">
                <img src={SavingsImage} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + SavingsImage + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Success>
                <h6>Family Budget</h6>
              </Success>
              <h4 className={classes.cardTitle}>
                <a href="https://www.advisor.ca/my-practice/conversations/cash-flow-and-emergency-funds-a-vital-part-of-financial-planning/" target="_blank">
                  Cash Flow and Emergency Funds
                </a>
              </h4>
              <p className={classes.description}>
                With pandemic uncertainty still present, financial advisors are stressing the importance of managing cash flow and having an emergency fund. (www.advisor.ca)
                <a href="https://www.advisor.ca/my-practice/conversations/cash-flow-and-emergency-funds-a-vital-part-of-financial-planning/" target="_blank"> Read More </a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <a href="#pablo">
                <img src={CryptocurrencyImage} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + CryptocurrencyImage + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Danger>
                <h6>INCOME TAX</h6>
              </Danger>
              <h4 className={classes.cardTitle}>
                <a href="https://www.advisor.ca/tax/tax-news/income-tax-returns-for-2021-what-you-need-to-know/" target="_blank">
                  Income tax returns for 2021
                </a>
              </h4>
              <p className={classes.description}>
                   The Canada Revenue Agency highlights new or adjusted credits and deductions for 2021 income tax returns. (www.advisor.ca)
                <a href="https://www.advisor.ca/tax/tax-news/income-tax-returns-for-2021-what-you-need-to-know/" target="_blank"> Read More </a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
