import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";

import InvestmentsImage from "assets/img/examples/blog-trading.jpg";
import CryptocurrencyImage from "assets/img/update1/businesspeople-celebrating-success.jpg";
import SavingsImage from "assets/img/update1/chatbot3.jpg";
import TechnologyImage from "assets/img/blog/male-hand-holding-phone_155003-6466.jpg";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";

const useStyles = makeStyles(sectionInterestedStyle);

export default function SectionInterested() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
              <a href="#">
                <img src={InvestmentsImage} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + InvestmentsImage + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Info>
                <h6>FINTECH</h6>
              </Info>
              <h4 className={classes.cardTitle}>
                <a href="https://www.forbes.com/sites/rexsalisbury/2022/12/20/my-6-predictions-for-fintech-in-2023/?sh=5f50a6127866" target="_blank">
                  Predictions for Fintech In 2023
                </a>
              </h4>
              <p className={classes.description}>
                Top 6 predictions for Fintech in 2023 by the founder of Cambrian, Rex Salisbury. (www.forbes.com)
                <a href="https://www.forbes.com/sites/rexsalisbury/2022/12/20/my-6-predictions-for-fintech-in-2023/?sh=5f50a6127866" target="_blank"> Read more</a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <a href="#pablo">
                <img src={SavingsImage} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + SavingsImage + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Success>
                <h6>AI technology</h6>
              </Success>
              <h4 className={classes.cardTitle}>
                <a href="https://www.advisor.ca/my-practice/conversations/cash-flow-and-emergency-funds-a-vital-part-of-financial-planning/" target="_blank">
                  Tapping into OpenAI's GPT-4
                </a>
              </h4>
              <p className={classes.description}>
                  Read on how major companies such as Morgan Stanley and Stripe tap into OpenAI's GPT-4. (www.finextra.com)
                <a href="https://www.advisor.ca/my-practice/conversations/cash-flow-and-emergency-funds-a-vital-part-of-financial-planning/" target="_blank"> Read More </a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <a href="#pablo">
                <img src={CryptocurrencyImage} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + CryptocurrencyImage + ")",
                  opacity: "1"
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Danger>
                <h6>IPOs</h6>
              </Danger>
              <h4 className={classes.cardTitle}>
                <a href="https://techcrunch.com/2023/10/18/all-hail-the-eventual-plaid-ipo/" target="_blank">
                  (Eventual) Plaid IPO
                </a>
              </h4>
              <p className={classes.description}>
                   Fintech industry is looking forward to an eventual IPO by Plaid. (techcrunch.com)
                <a href="https://techcrunch.com/2023/10/18/all-hail-the-eventual-plaid-ipo/" target="_blank"> Read More </a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
