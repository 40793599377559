/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Mail from "@material-ui/icons/Mail";
import Favorite from "@material-ui/icons/Favorite";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Footer from "components/Footer/Footer.js";

import face1 from "assets/img/faces/card-profile6-square.jpg";
import face2 from "assets/img/faces/christian.jpg";
import face3 from "assets/img/faces/card-profile4-square.jpg";
import face4 from "assets/img/faces/card-profile1-square.jpg";
import face5 from "assets/img/faces/marc.jpg";
import face6 from "assets/img/faces/kendall.jpg";
import face7 from "assets/img/faces/card-profile5-square.jpg";
import face8 from "assets/img/faces/card-profile2-square.jpg";
import axios from 'axios';

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";

const useStyles = makeStyles(styles);

export default function SectionFooter() {
  const classes = useStyles();
  const [email, setEmail] = React.useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    axios.post(`/email/subscribe`, "Email: " + email, {headers: {'Content-Type': 'text/plain'}})
      .then(res => {
        alert('thank you, your request has been sent');
      }).catch(error => {
        alert('Our appologies, the message could not be sent');
      })

  }  

  return (
    <div className={classes.section}>
      <div>
        <Footer
          theme="white"
          content={
            <div>
              <div
                className={classNames(classes.pullCenter, classes.copyRight)}
              >
                Copyright &copy; {1900 + new Date().getYear()}{" "}
                <a href="https://www.planlogix.io">
                  PlanLogix
                </a>{" "}
              </div>
            </div>
          }
        >
          <div className={classes.footer}>
            <GridContainer>
              <GridItem xs={12} sm={3} md={3}>
                <a href="#pablo">
                  <h5>PlanLogix</h5>
                </a>
                <p>
                  Financial Planning Solutions
                </p>
              </GridItem>
              <GridItem xs={12} sm={2} md={2}>
                <h5>About</h5>
                <ul className={classes.linksVertical}>
                  <li>
                    <a href="#products">Products</a>
                  </li>
                  <li>
                    <a href="#services">Services</a>
                  </li>
                  <li>
                    <a href="#capabilities">Why Us</a>
                  </li>
                  <li>
                    <a href="#team">About Us</a>
                  </li>
                  <li>
                    <a href="#contact">Contact</a>
                  </li>
                </ul>
              </GridItem>
              <GridItem xs={12} sm={2} md={2}>
                <h5>Blog</h5>
                <ul className={classes.linksVertical}>
                  <li>
                    <a href="/blog/2024">2024 - Financial Guidance</a>
                  </li>
                  <li>
                    <a href="/blog/2023">2023</a>
                  </li>
                  <li>
                    <a href="/blog/2022">2022</a>
                  </li>
                  <li>
                    <a href="/blog/2021">2021</a>
                  </li>
                  <li>
                    <a href="/blog/2020">2020</a>
                  </li>
                </ul>
              </GridItem>
              <GridItem xs={12} sm={5} md={5}>
                <h5>Subscribe to Newsletter</h5>
                <p>
                  Join our newsletter and get PlanLogix updates in your inbox every month!  You have the ability to opt out at any time.
                </p>
                <form>
                  <CustomInput
                    id="footeremail"
                    formControlProps={{
                      fullWidth: false,
                      className: classes.customFormControl
                    }}
                    inputProps={{
                      placeholder: "Your Email...",
                      value: email,
                      onChange: handleEmailChange
                    }}
                    
                  />
                  <Button color="primary" justIcon onClick={handleSubmit}>
                    <Mail />
                  </Button>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </Footer>
      </div>
    </div>
  );
}
