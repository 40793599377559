import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Mail from "@material-ui/icons/Mail";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import axios from 'axios';

import sectionSubscribeLineStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionSubscribeLineStyle.js";

const useStyles = makeStyles(sectionSubscribeLineStyle);

export default function SubscribeLine() {
  const classes = useStyles();
  const [email, setEmail] = React.useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    axios.post(`/email/subscribe`, "Email: " + email, {headers: {'Content-Type': 'text/plain'}})
      .then(res => {
        alert('thank you, your request has been sent');
      }).catch(error => {
        alert('Our appologies, the message could not be sent');
      })

  }  

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Get Monthly Updates</h3>
            <p className={classes.description}>
              If you found this blog post useful, you can subscribe to get notified when a new update is available. We monitor and carefully select important financial planning-related updates so you don't have to. You have the ability to opt out at any time.
            </p>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <Card plain className={classes.cardClasses}>
              <CardBody formHorizontal plain>
                <form>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={8}
                      md={8}
                      className={classes.alignItemsCenter}
                    >
                      <CustomInput
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                          className: classes.formControl
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Mail className={classes.icon} />
                            </InputAdornment>
                          ),
                          placeholder: "Your Email...",
                          value: email,
                          onChange: handleEmailChange                          
                        }}
                      />               
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <Button round block color="primary" onClick={handleSubmit}>
                        Subscribe
                      </Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
