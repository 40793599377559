import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
import Box from "@material-ui/core/Box";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";

import InvestmentsImage from "assets/img/examples/blog-trading.jpg";
import CryptocurrencyImage from "assets/img/update1/businesspeople-celebrating-success.jpg";
import SavingsImage from "assets/img/update1/chatbot3.jpg";
import TechnologyImage from "assets/img/blog/male-hand-holding-phone_155003-6466.jpg";

import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";

import cardBlog4 from "assets/img/examples/card-blog4.jpg";
import office2 from "assets/img/office2.jpg";
import blog8 from "assets/img/examples/blog8.jpg";

import Button from "components/CustomButtons/Button.js";
import Media from "components/Media/Media.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import placeholder from "assets/img/placeholder.jpg";
import axios from 'axios';

const useStyles = makeStyles(sectionInterestedStyle);

export default function SectionInterested() {
  const classes = useStyles();

  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [message, setMessage] = React.useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handleNameChange = (event) => {
    setName(event.target.value);
  }

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const message_body = "Email: " + email + ", Name: " + name + ", Message: " + message;

    axios.post(`/email/blog`, JSON.stringify(message_body).slice(1, -1), {headers: {'Content-Type': 'text/plain'}})
      .then(res => {
        alert('thank you, your message has been sent');
      })

  }

  return (
    <div className={classes.blog}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={10}
            md={10}
            className={classes.mlAuto + " " + classes.mrAuto}
          >
            <GridContainer>
              <GridItem xs={12} sm={3} md={3} />
            </GridContainer>
            <Card plain blog className={classes.card}>
              <GridContainer>
                <GridItem xs={12} sm={3} md={3} />
                <GridItem xs={12} sm={9} md={9}>
                  <h2 className={classes.cardTitle}>
                    Finance Guidance Technology in 2024
                  </h2>
                  <Box m={5} />
                  <h3 className={classes.cardTitle}>
                    Introduction
                  </h3>
                  <Box m={5} />
                  <h4 className={classes.description}>
                    Let's celebrate the financial industry's remarkable journey with a round of applause, acknowledging significant recent achievements, like offering consumers many new choices and user-friendly financial products, services, and tools. That’s a lot of effort by many smart, well-educated and very experienced folk, who contribute to the ongoing improvement of financial accessibility. These achievements ultimately result in empowering more individuals with quality financial guidance and enabling them to make sound personal financial decisions.
                  </h4>
                  <Box m={2} />
                  <h4 className={classes.description}>
                    In this blog series, we would like to share our view about the current state of Financial Guidance Technology. This type of technology is also sometimes called “Personal Finance Software” or “Personal Finance Management” when it’s used by consumers, and “Financial Planning Software” in the case when it is used by financial advisors. It comes in various shapes and forms, all geared towards offering personalized financial suggestions and aiding consumers in making informed financial decisions, either in automated/self-serve fashions, or with the help of a professional financial advisor.
                  </h4>
                </GridItem>
              </GridContainer>
            </Card>
            <Card plain blog className={classes.card}>
              <GridContainer>
                <GridItem xs={12} sm={3} md={3} />
                <GridItem xs={12} sm={9} md={9}>
                  <h3 className={classes.cardTitle}>
                    What was done well in the last 10 years
                  </h3>
                  <Box m={5} />
                  <h4 className={classes.cardTitle}>
                    #1. Rise of Financial Guidance Technology and Fintech in general
                  </h4>
                  <h4 className={classes.description}>
                    Even though Financial Guidance Technology has existed for many years and even decades, it really took off on a large scale only lately, fuelled by account aggregation capabilities that help automate collecting the essential financial data of consumers, as well as Fintech becoming an important driver for innovation in the Financial Services industry. Financial Guidance is one of the categories of a large and vibrant Fintech space, along with other core services such as banking, payments, lending, insurance and many other categories.
                  </h4>
                  <Box m={3} />
                  <h5 className={classes.cardTitle}>
                    #2. Rise of Robo-Advisors
                  </h5>
                  <h4 className={classes.description}>
                    Similarly, automated investment management has been in use internally by many companies for a long time, but it got a significant exposure to the consumers within the last 10 years or so, providing an alternative to the more expensive human advisor-based investment management services. It brings accessible investment services to the masses, providing a self-serve experience for automated portfolio selection and investment management tools that are as easy-to-use as ever. It is also often complemented with light financial guidance capabilities and professional human help as a value add-on, or as a paid service.
                  </h4>
                  <Box m={3} />
                  <h5 className={classes.cardTitle}>
                    #3. Financial Data Aggregators
                  </h5>
                  <h4 className={classes.description}>
                    Financial Data Aggregation began to emerge in the early 2000s, initially offered by a handful of vendors. However, its widespread adoption took off in the last decade, facilitating the consolidation of financial data from multiple institutions for consumers. This data typically includes information from personal and small business accounts in banking, investment, and various other products and services commonly provided by financial services companies. Beyond its role in personal finance applications, the surge in account aggregation has introduced numerous valuable applications for financial organizations, such as identity management, payments, lending, and various other use cases.
                  </h4>
                </GridItem>
              </GridContainer>
            </Card>
            <Card plain blog className={classes.card}>
              <GridContainer>
                <GridItem xs={12} sm={3} md={3} />
                <GridItem xs={12} sm={9} md={9}>
                  <h3 className={classes.cardTitle}>
                    Aspects that still require attention
                  </h3>
                  <Box m={5} />
                  <h5 className={classes.description}>
                    According to many sources, there are still significant gaps in the actual financial guidance received by consumers.
                  </h5>
                  <Box m={3} />
                  <h5 className={classes.cardTitle}>
                    #1. Professional advice is too expensive for most consumers
                  </h5>
                  <h4 className={classes.description}>
                    With some exceptions, most financial organizations offer personalized advice through financial advisors only after clients have invested a minimum, typically a substantial amount of money. Understandably, personal advice from professionals is not easily scalable as a free service, considering the value and custom-tailored approach it provides. However, this also implies that a significant portion of the population is not receiving proper financial guidance unless they heavily engage in do-it-yourself (DIY) activities.
                  </h4>
                  <Box m={3} />
                  <h5 className={classes.cardTitle}>
                    #2. Financial advice technology provided by Robo-Advisors
                  </h5>
                  <h4 className={classes.description}>
                    Despite the unquestionable value that robo-advisors bring through automated investment services, their financial advice technology could often be enhanced to provide more personalized experiences. While robo-advisors often provide calculators and send emails with general financial tips, these resources are widely available elsewhere. Nevertheless, considering the complexity involved in delivering their investment services, with challenges ranging from financial and technical aspects to regulatory compliance, these companies deserve praise for their offerings and achievements.
                  </h4>
                  <Box m={3} />
                  <h5 className={classes.cardTitle}>
                    #3. Standards in exchanging consumers' financial data
                  </h5>
                  <h4 className={classes.description}>
                    While a substantial number of consumers, particularly the younger demographic, actively utilize tools for aggregating their financial data, many remain hesitant due to concerns about the security of their accounts and the privacy of their personal information. Presently, data aggregators still often have to rely on screen scraping technology, a method associated with several drawbacks. Fortunately, there is a noticeable shift in focus towards standards like Open Banking, which will bring substantial improvements in security, connectivity, consumer concent for sharing only selected data, and other aspects.
                  </h4>
                </GridItem>
              </GridContainer>
            </Card>
            <Card plain blog className={classes.card}>
              <GridContainer>
                <GridItem xs={12} sm={3} md={3} />
                <GridItem xs={12} sm={9} md={9}>
                  <h3 className={classes.cardTitle}>
                    What can be improved?
                  </h3>
                  <Box m={5} />
                  <h4 className={classes.description}>
                    What can be done to improve the level of personalized financial guidance?
                  </h4>
                  <Box m={3} />
                  <h5 className={classes.cardTitle}>
                    #1. Implementing more automation for the mass consumers
                  </h5>
                  <h4 className={classes.description}>
                    Many people need financial advice and yet, after 10 years of significant technical progress, there are still large gaps in the mass consumers’ ability to receive meaningful and quality advice. Understandably, professional human advice is not cheap (for a valid reason), therefore more automation is required to provide financial guidance tailored to a specific situation. It’s a complex topic that often deeply intervenes with people’s personal lives, so it is not just numbers. But basic, common-sense pointers in regard to their <u>individual</u> financial situation is often all they need to get on the right track. Leave the complex stuff and dealing with the human aspects to professionals, not robots.
                  </h4>
                  <Box m={3} />
                  <h5 className={classes.cardTitle}>
                    #2. Adopting a more holistic approach to understanding consumers' needs
                  </h5>
                  <h4 className={classes.description}>
                    It is easier than ever to offer automated budget tools and financial calculators to your clients. However, helping individuals goes beyond tracking account balances and spending habits, or, quickly crunching a few numbers on a specific topic. In our view, to really provide a meaningful level of even just basic financial guidance, companies need to understand more about their customers’ circumstances and to provide deeper analysis on specific topics. It should be able to accomplish this these days without time-consuming manual labor or spending a fortune.
                  </h4>
                  <Box m={3} />
                  <h5 className={classes.cardTitle}>
                    #3. Educating consumers
                  </h5>
                  <h4 className={classes.description}>
                    In addition to solving the issues with automation and receiving more holistic guidance, consumers still need to be educated about general personal finance topics, in order to better understand their choices and the importance of the financial decisions they make or don’t make. There are multiple reports that many people in their 50’s still don’t think about their retirement and don’t understand the basics despite its importance. Or families that should really take care of their debt levels, but don’t pay attention to that. Many consumers still need to understand that managing finances is their own responsibility and they need to be doing it correctly.
                  </h4>
                </GridItem>
              </GridContainer>
            </Card>
            <Card plain blog className={classes.card}>
              <GridContainer>
                <GridItem xs={12} sm={3} md={3} />
                <GridItem xs={12} sm={9} md={9}>
                  <h3 className={classes.cardTitle}>
                    In Conclusion
                  </h3>
                  <Box m={5} />
                  <h4 className={classes.description}>
                    In summary, the Financial Services industry has made numerous valuable additions, providing consumers with more choices than ever. However, despite significant automation and other technical advancements, there is still much work to be done in delivering personalized experiences and meaningful financial guidance to mass consumers.
                  </h4>
                  <Box m={2} />
                  <h4 className={classes.description}>
                    We hope you enjoyed reading this blog! Please feel free to submit any feedback or suggestions below.
                  </h4>
                      <div>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6}>
                            <CustomInput
                              id="not-logged-name"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                placeholder: "Your Name",
                                value: name,
                                onChange: handleNameChange                                
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <CustomInput
                              id="not-logged-email"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                placeholder: "Your Email",
                                value: email,
                                onChange: handleEmailChange
                              }}                              
                            />
                          </GridItem>
                        </GridContainer>
                        <CustomInput
                          id="not-logged-message"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            multiline: true,
                            rows: 6,
                            placeholder: "Your Feedback and Suggestions...",
                            value: message,
                            onChange: handleMessageChange                            
                          }}
                        />
                      </div>
                      <div className={classes.signInButton}>
                        <Button color="primary" className={classes.floatRight} onClick={handleSubmit}>
                          Submit
                        </Button>
                      </div>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
