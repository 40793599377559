import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import GroupWork from "@material-ui/icons/GroupWork";
import Airplay from "@material-ui/icons/Airplay";
import LocationOn from "@material-ui/icons/LocationOn";
import Extension from "@material-ui/icons/Extension";
import ChildFriendly from "@material-ui/icons/ChildFriendly";
import WatchLater from "@material-ui/icons/WatchLater";
import Code from "@material-ui/icons/Code";
import FormatPaint from "@material-ui/icons/FormatPaint";
import Dashboard from "@material-ui/icons/Dashboard";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import AccessTime from "@material-ui/icons/AccessTime";
import AttachMoney from "@material-ui/icons/AttachMoney";
import InsertChartIcon from '@material-ui/icons/InsertChart';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import DevicesIcon from '@material-ui/icons/Devices';
import ChatIcon from '@material-ui/icons/Chat';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import CreditCardIcon from '@material-ui/icons/CreditCard';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';  
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PeopleIcon from '@material-ui/icons/People';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import componentsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/componentsStyle.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

import iphone from "assets/img/sections/iphone.png";
import iphone2 from "assets/img/test_collaboration_10.png";
import bg5 from "assets/img/card-project11.jpg";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div>
        {/* Feature 5 START */}
        <div
          className={classes.features5}
          style={{ backgroundImage: `url(${bg5})` }}
        >
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>PlanLogix as Your Partner</h2>
            </GridItem>
            <div className={classes.container}>
              <GridContainer className={classes.gridContainer}>
                <GridItem xs={12} sm={6} className={classes.gridItem}>
                  <InfoArea
                    vertical
                    className={classes.infoArea5}
                    icon={MonetizationOnIcon}
                    title="Financial Planning Expertise"
                    description={
                      <p>
                        Highly qualified and educated financial professionals have always been an integral part of the PlanLogix team. We provide valuable insights, backed by many years of related industry experience and a complete understanding of your practical needs.
                      </p>
                    }
                    iconColor="info"
                  />
                </GridItem>
                <GridItem xs={12} sm={6} className={classes.gridItem}>
                  <InfoArea
                    vertical
                    className={classes.infoArea5}
                    icon={DevicesIcon}
                    title="Seasoned Technical Talent"
                    description={
                      <p>
                        Our team members bring decades of experience in developing financial systems and versatile knowledge in enterprise architecture, designing and running financial applications, which results in navigating any technical challenges with a high degree of confidence.
                      </p>
                    }
                  />
                </GridItem>                
              </GridContainer>
              <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={6} className={classes.gridItem}>
                  <InfoArea
                    vertical
                    className={classes.infoArea5}
                    icon={PeopleIcon}
                    title="Trustworthy Vendor"
                    description={
                      <p>
                        We understand that it requires a high level of confidence to trust someone else with your business and we are committed to high standards of professional and personal integrity that result in healthy multi-year business relationships with our clients.
                      </p>
                    }
                    iconColor="danger"
                  />
                </GridItem>
                <GridItem xs={12} sm={6} className={classes.gridItem}>
                  <InfoArea
                    vertical
                    className={classes.infoArea5}
                    icon={ViewCarousel}
                    title="Continuous Innovation"
                    description={
                      <p>
                       It feels comfortable to continue to work the same way we always have, but the world is constantly changing. While respecting existing standards, we are constantly in search for new areas of practical and meaningful improvements in every-day business scenarios.
                      </p>
                    }
                  />
                </GridItem>
              </GridContainer>
            </div>
          </GridContainer>
        </div>
        {/* Feature 5 END */}
      </div>
    </div>
  );
}
