import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Muted from "components/Typography/Muted.js";
import Button from "components/CustomButtons/Button.js";

import bg10 from "assets/img/bg10.jpg";
import VirtualEventImage from "assets/img/blog/business-video-call-laptop_23-2148667505.jpg";

import cardProfile1 from "assets/img/faces/card-profile1-square.jpg";
import cardProfile4 from "assets/img/faces/card-profile4-square.jpg";

import sectionImageStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionImageStyle.js";
import { FormatAlignLeft } from "@material-ui/icons";
import { Link } from "@material-ui/core";

const useStyles = makeStyles(sectionImageStyle);

export default function SectionImage() {
  const classes = useStyles();

  return (
    <div
      className={classes.section}
      style={{ backgroundImage: "url(" + bg10 + ")" }}
    >
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12}>
            <Card profile plain className={classes.textLeft}>
              <GridContainer>
                <GridItem xs={12} sm={5} md={5}>
                  <CardHeader image plain>
                    <a href="https://www.financialplanningweek.ca" target="_blank" onClick="event.preventDefault()">
                      <img src={VirtualEventImage} alt="..." />
                    </a>
                  </CardHeader>
                </GridItem>
                <GridItem xs={12} sm={7} md={7}>
                  <CardBody plain>
                    <h4 className={classes.cardTitle}>Financial Planning Week 2020</h4>
                    <Muted>
                      <h6>EVENT OF THE MONTH</h6>
                    </Muted>
                    <p className={classes.description}>
                      FP Canada™ hosted Financial Planning Week virtually this year, given the pandemic and gathering restrictions in place across Canada. More than 1700 participants logged in for the sessions, with the majority of them for the very first time. It was hosted by very notable speakers on important topics such as resilience, engagement with clients, retirement and ethics (www.financialplanningweek.ca)
                    </p>
                    <a href="https://www.financialplanningweek.ca" target="_blank" onClick="event.preventDefault()">
                      <Button round color="danger">
                          <FormatAlignLeft className={classes.icons} /> READ MORE
                      </Button>
                    </a>
                  </CardBody>
                  {/*
                  <CardFooter profile plain>
                    <Button justIcon simple color="white" href="https://twitter.com/OfficialFPCan">
                      <i className="fab fa-twitter" />
                    </Button>
                    <Button justIcon simple color="white" href="https://www.facebook.com/OfficialFPCanada">
                      <i className="fab fa-facebook-square" />
                    </Button>
                    <Button justIcon simple color="white" href="https://www.youtube.com/c/FPCanadaOfficial">
                      <i className="fab fa-youtube" />
                    </Button>
                  </CardFooter>
                  */}
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
