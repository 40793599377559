/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import BlogLinks from "components/Header/BlogLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import SectionPills from "./Sections/SectionPills.js";
import SectionInterested from "./Sections/SectionInterested.js";
import SectionImage from "./Sections/SectionImage.js";
import SubscribeLine from "./Sections/SubscribeLine.js";

import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import SectionText from "./Sections/SectionText.js";
import { Box, Button } from "@material-ui/core";


const useStyles = makeStyles(blogPostsPageStyle);
const usePresentationStyles = makeStyles(presentationStyle);

export default function BlogPostsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const presentationClasses = usePresentationStyles();
  return (
    <div>
      <Header
        brand="PlanLogix"
        links={<BlogLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "info"
        }}
      />
      <Parallax image={require("assets/img/examples/city.jpg")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
              <h2 className={classes.title}>
                Financial Planning Industry News and Events
              </h2>
              <h4 className={classes.title}>
                Year 2022 in Review
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <SectionText />
          <SectionInterested />
        </div>
        <SectionImage />
        <SubscribeLine />
      </div>
      <Box m={10} />
      <Footer
        theme="white"
        content={
          <div>
            <div className={presentationClasses.left}>
              <a
                href="http://www.planlogix.io"
                className={presentationClasses.footerBrand}
              >
                PlanLogix
              </a>
            </div>
            <div className={presentationClasses.pullCenter}>
            </div>
          </div>
        }
      />
    </div>
  );
}
